export default [
  // {
  // 	header: 'Content'
  // },
  {
    title: "Users",
    route: "users",
    icon: "UserIcon",
  },
  {
    title: "Providers",
    route: "providers",
    icon: "UsersIcon",
  },
  {
    title: "Interviews",
    route: "interviews",
    icon: "ClipboardIcon",
  },
  {
    title: "Withdrawal",
    route: "withdrawal",
    icon: "CreditCardIcon",
  },
  {
    title: "Transaction",
    route: "transaction",
    icon: "RepeatIcon",
  },
  {
    title: "Business Group",
    route: "business-group",
    icon: "BriefcaseIcon",
  },
  {
    title: "Category",
    route: "category",
    icon: "FileTextIcon",
  },
  {
    title: "Sub-category",
    route: "sub-category",
    icon: "FileTextIcon",
  },
  {
    title: "Notification",
    route: "notification",
    icon: "BellIcon",
  },
  {
    title: "Customer Bypass",
    route: "customer-bypass",
    icon: "LogInIcon",
  },
];
